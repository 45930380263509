<template>
  <div>
    <Header ref="header"/>

    <div class="w w1390">
      <div class="crumbs-nav">
        <div class="crumbs-nav-main clearfix">
          <div class="crumbs-nav-item" v-for="(cate,index) in CrumbsCategories" v-bind:key="cate.id">
            <div class="menu-drop">
              <div class="trigger">
                <router-link :to="{path:'category',query:{id:cate.id}}">
                  <span>{{cate.name}}</span>
                </router-link>
              </div>
            </div>
            <i class="iconfont icon-right" v-if="index != CrumbsCategories.length-1"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="w w1390">
        <div class="filter">
          <div class="filter-wrap">
            <div class="filter-sort">
              <a href="javascript:void(0);" v-on:click="orderPrice">
                价格<i class="iconfont" :class="priceIcon"></i>
              </a>
            </div>
            <div class="filter-range">

            </div>
            <div class="filter-right">
              <div class="button-page">
                <span class="pageState"><span>{{paging.page}}</span>/{{ paging.totalPages }}</span>
                <a href="javascript:void(0);" title="上一页" v-on:click="handlePreChange"><i class="iconfont icon-left"></i></a>
                <a href="javascript:void(0);" title="下一页" v-on:click="handleNextChange"><i class="iconfont icon-right"></i></a>
              </div>

              <div class="styles">
                <ul class="items">
                  <li :class="['item',{'current':listType}]" v-on:click="listType = !listType" v-show="listType">
                    <a href="javascript:void(0)" title="大图模式">
                      <span class="iconfont icon-switch-grid"></span>大图
                    </a>
                  </li>
                  <li :class="['item',{'current':!listType}]" v-on:click="listType = !listType" v-show="!listType">
                    <a href="javascript:void(0)" title="小图模式">
                      <span class="iconfont icon-switch-list"></span>小图
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="g-view w">
          <div class="goods-list" v-loading="SearchLoading" element-loading-text="拼命加载中......">
            <div class="tip">
              <p>温馨提示：部分商品包装更换频繁，如货品与图片不完全一致，请以收到的商品实物为准。</p>
            </div>
            <div class="gl-warp gl-warp-large" :style="{display:listType ? 'block' : 'none'}" v-if="paging.list && paging.list.length > 0">
              <div class="goods-list-warp" v-if="carts.length > 0">
                <ul>
                  <li class="gl-item" v-for="(good,index) in paging.list" v-bind:key="good.id">
                    <div class="gl-i-wrap">
                      <div class="p-img">
                        <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                          <img :src="getCoverImage(good.images)">
                        </router-link>
                      </div>
                      <div class="p-lie">
                        <div class="p-price">
                          <em>¥</em>{{good.price}}
                        </div>
                        <div class="original-price">
                          <em>¥</em>{{good.marketPrice}}
                        </div>
                      </div>
                      <div class="p-name">
                          <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                            {{good.name}}<template v-if="good.brandName">({{good.brandName}})</template>
                          </router-link>
                      </div>
                      <div class="sepc">
                        <p v-for="(para,index) in good.paras" v-bind:key="index">{{para.name}}：{{para.value}}</p>
                      </div>
                      <div class="brief" v-if="good.subName">
                        [促]
                        <p v-for='(b,i) in good.subName.split("$$")' v-bind:key="i">
                          <template v-if="b.indexOf('仅台州瑞人堂') == -1 ">{{b}}</template>
                          <!--                          <template v-else>{{b}}</template>-->
                        </p>
                      </div>
                      <div class="p-operate">
                        <amount-box v-model="carts[index].addNum" :defaultVal="carts[index].addNum" :max="99999" :min="0"></amount-box>
                        <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart">
                          <i class="iconfont icon-carts"></i>加入购物车
                        </a>
                        <div style="clear: both"/>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="gl-warp gl-warp-samll" :style="{display:!listType?'block':'none'}" v-if="paging.list && paging.list.length > 0">
              <div class="goods-list-warp" v-if="carts.length > 0">
                  <ul>
                    <li :class="[index%2==0?'gl-h-item':'gl-h-item item_bg']" class="" v-for="(good,index) in paging.list" v-bind:key="good.id">
                      <div class="gl-i-wrap">
                        <div class="col col-1">
                          <div class="p-img">
                            <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                              <img :src="getCoverImage(good.images)">
                            </router-link>
                          </div>
                          <div class="p-right">
                            <div class="p-name">
                              <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                                {{good.name}} <template v-if="good.brandName">({{good.brandName}})</template>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="col col-2">
                          <div class="p-lie">
                            <ul>
                              <li v-for="(para,index) in good.paras" v-bind:key="index">{{para.name}}：{{para.value}}</li>
                            </ul>
                          </div>
                        </div>
                        <div class="col col-3">
                          <div class="p-price">
                            <div class="shop-price">
                              <em>¥</em>{{good.price}}
                            </div>
                            <div class="original-price">
                              <em>¥</em>{{good.marketPrice}}
                            </div>
                          </div>
                        </div>
                        <div class="col col-4">
                          <div class="p-operate">
                            <number-box :defaultVal="carts[index].addNum" v-model="carts[index].addNum" @on-change="onParamChange(index, $event)" :max="99999" :min="0" :step="2" @keyup.enter.native="addCart(index,good)"></number-box>
                            <el-badge :value="carts[index].num" class="item" v-if="carts[index].num>0">
                              <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart">
                                <i class="iconfont icon-carts"></i>加入购物车
                              </a>
                            </el-badge>
                            <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart" v-if="carts[index].num<=0">
                              <i class="iconfont icon-carts"></i>加入购物车
                            </a>
                          </div>
                        </div>
                        <div class="brief" v-if="good.subName">
                          [促]
                          <p v-for='(b,i) in good.subName.split("$$")' v-bind:key="i">
                            <template v-if="b.indexOf('仅台州瑞人堂') == -1 ">{{b}}</template>
                            <!--<template v-else>{{b}}</template>-->
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
            </div>

            <div class="tc" v-if="paging.list && paging.list.length > 0">
              <div class="pages" id="pager">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pageNow"
                    :page-size="20"
                    :total="totalCount">
                </el-pagination>
              </div>
            </div>

            <div class="no_records" v-if="paging.list && paging.list.length == 0">
              <i class="no_icon_two"></i>
              <div class="no_info no_info_line">
                <h3>抱歉，没有找到符合条件的数据</h3>
                <div class="no_btn">
                  <router-link :to="{path:'/'}" class="btn sc-redBg-btn">
                    返回首页
                  </router-link>
                </div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="goods-spread">
            <a href="javascript:void(0);" class="g-stop">
              <i class="iconfont icon-right"></i>
            </a>
            <div class="gs-warp">
              <div class="gs-tit">推广商品</div>
              <ul class="gs-list">
                <li class="opacity_img" v-for="(good) in extensions" v-bind:key="good.id">
                  <div class="">
                    <div class="p-img">
                      <router-link :to="{path:'goods',query:{ id:good.id }}" target="_blank" tag="a">
                        <img :src="getCoverImage(good.images)" width="190" height="190">
                      </router-link>
                    </div>
                    <div class="p-price">¥{{ good.price }}</div>
                    <div class="p-name">
                      <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                        {{good.name}}
                      </router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <guess-love :count="7"></guess-love>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import NumberBox from '@/components/NumberBox/Index'
import AmountBox from '@/components/AmountBox/Index'
import GuessLove from '@/components/GuessLove/Index'
// eslint-disable-next-line no-unused-vars
import {getCartList, setCartNum} from "@/api/user";
import { cartCreate, getGoodsSearch} from "@/api/good";
import {getCategoryCrumbs} from "@/api/category";

export default {
  name: "Category",
  components: {
    Header,
    Footer,
    NumberBox,
    AmountBox,
    GuessLove
  },
  data() {
    return {
      extensions : [],      //推广
      guesses : [],         //猜喜欢
      paging : {},
      carts : [],
      CrumbsCategories: [], //面包屑
      id : 0,
      pageNow : 1,
      totalCount : 0,
      totalPage:0,
      SearchLoading:false,

      priceOrder:'price asc',
      priceIcon: 'icon-arrow-up',
      listType: false,
      sidx: 'Id',
      sord: 'desc',

      cartList:[]
    }
  },
  watch: {
    $route: {
      handler: function(){
        this.keywords = this.$route.query.keywords
        this.getGoods()
        this.getCrumbsCategories()
      }
    },
    pageNow:{
      handler: function(){
        this.getGoods()
        this.getCrumbsCategories()
      }
    }
  },
  created() {
    this.getExtensions();
    this.getGuesses();
    this.getGoods();
    this.getCrumbsCategories();
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  computed:{
  },
  methods:{
    getExtensions(){
      getGoodsSearch({
        currentPage: 1,
        pageSize: 6,
        sidx: 'newid()',
      }).then(res => {
        this.extensions = res.data.list
      });
    },
    getGuesses(){
      getGoodsSearch({
        currentPage: 1,
        pageSize: 7,
        sidx: 'newid()',
      }).then(res => {
        this.guesses = res.data.list
      });
    },
    getGoods(){
      this.carts = []
      this.cartList = []
      this.SearchLoading = true
      getGoodsSearch({
        // page:this.pageNow,
        // limit:20,
        // order:this.priceOrder,

        currentPage: this.pageNow,
        pageSize: 20,
        sidx: this.sidx,
        sord: this.sord,
        categories: this.$route.query.id

      }).then(res => {
        this.paging = res.data
        this.totalCount = res.data.pagination.totalRecord
        this.totalPage = res.data.pagination.totalPage

        const goodsIds = res.data.list.map((item) => {
          return item.id;
        });

        // this.totalCount = res.data.totalCount
        // this.totalPage = res.data.totalPages
        //
        // var productIds = res.data.list.map((item)=>{
        //   return item.productId;
        // })
        //获取当页购物车信息，如果获取全部代价有点大（比如有些客户购物出列表300+）
        getCartList({goodsIds:goodsIds.join(',')}).then(res=>{
          this.cartList = res.data.normal
          this.paging.list.forEach((i)=>{
            // if(i.image.indexOf("image.yabyy.com") == -1){
            //   i.image = settings.AdminUrl + i.image
            // }

            if(i.paramsData != null){
              i.paras = JSON.parse(i.paramsData.replace(/[\r|\n|\t]/g,''));
            }else{
              i.paras = []
            }

            let has = this.cartList.filter(item=>item.goodsId == i.goodsId)
            if(has.length>0){
              i.num = has[0].num
              i.addNum = 0
              this.carts.push({num: has[0].num,addNum:0});
            }else{
              i.num = 0
              i.addNum = 0
              this.carts.push({num: 0,addNum:0});
            }
          })
        })
      }).finally(()=>{
        this.SearchLoading = false
      })
    },
    getCrumbsCategories(){
      // getCrumbsCategories({
      //   id: this.$route.query.id
      // }).then(res => {
      //   this.CrumbsCategories = res.data.list.reverse()
      // })

      getCategoryCrumbs(this.$route.query.id).then(res => {
        this.CrumbsCategories = res.data.reverse()
      });

    },
    onParamChange(index, val){
      this.carts[index].addNum = val
    },
    addCart(index,goods){
      if(this.carts[index].addNum <= 0){
        this.$message({
          message: '购物车数量必须大于0',
          type: 'warning'
        })
      }else{
        let _this = this


        cartCreate({
          goodsId: goods.id,
          goodsNo: goods.no,
          qty: this.carts[index].addNum,
          price: goods.price,
          signId: 2,
          //skuId: this.showSelect.Id,
          specInfo : goods.specs
        }).then((res) => {
          // if (res.code == 400){
          // 	_this.$h.toast(res.msg);
          // }else{
          //_this.$store.commit('cart/setCartAdd', true);
          //_this.$store.commit('cart/cartNum', true);
          //_this.$h.toast('加入购物车成功');

          if(res!=undefined){
            _this.$message({
              message: '加入购物车成功',
              type: 'success'
            });

            _this.carts[index].num = this.carts[index].num + this.carts[index].addNum;
            _this.carts[index].addNum = 0;

            _this.$store.dispatch('GetCartSum');
          }
        }).catch(err => {
          console.log(err);
          // _this.$h.toast(err);
          // _this.$toError(err);
        });
      }
    },
    handleCurrentChange(val) {
      this.pageNow = val
    },
    handlePreChange() {
      if(this.pageNow-1<=0)
        this.pageNow = 1
      else
        this.pageNow = this.pageNow -1
    },
    handleNextChange() {
      if(this.pageNow + 1 >= this.totalPage)
        this.pageNow = this.totalPage
      else
        this.pageNow = this.pageNow + 1
    },
    orderPrice(){
      if(this.priceOrder=='price asc'){
        this.priceOrder = 'price desc'
        this.sord = 'desc'
        this.sidx = 'Price'
        this.priceIcon = 'icon-arrow-down'
        this.getGoods()
      }else{
        this.priceOrder = 'price asc'
        this.sord = 'asc'
        this.sidx = 'Price'
        this.priceIcon = 'icon-arrow-up'
        this.getGoods()
      }
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },
  }
}
</script>

<style>
@import "../assets/style/select.css";

.p-name .el-button{
  padding: 0;
}

.gl-h-item .gl-i-wrap .col-1{ width:19%; margin-right:2%;}
.gl-h-item .gl-i-wrap .col-2{ width:40%; margin-right:1%;}
.gl-h-item .gl-i-wrap .col-3{ width:9%; margin-right:2%;}
.gl-h-item .gl-i-wrap .col-4{ width:27%; margin-right:0;}
.col .p-lie{ margin-top:0;}
.gl-i-wrap .p-name {margin-top: 0;}

@media screen and (min-width: 1230px) and (max-width: 1450px){
  .gl-h-item .gl-i-wrap .col-1{ width:19% !important; margin-right:2% !important;}
  .gl-h-item .gl-i-wrap .col-2{ width:40% !important;; margin-right:1% !important;;}
  .gl-h-item .gl-i-wrap .col-3{ width:9% !important;; margin-right:2% !important;;}
  .gl-h-item .gl-i-wrap .col-4{ width:27% !important;; margin-right:0 !important;;}
}

.p-num,.p-name{
  color: #000 !important;
  font-size: 13px;
}
.p-num{
  display: block !important;
}

.col-2 ul li{
  width: 49%;
  float: left;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
}

.col .p-name{
  height: 60px;
}
.col .p-name a{
  height: 60px;
  white-space: break-spaces;
}

.counter-component{float: left;padding-right: 5px;}
.counter-component input{width: 58px;}
.counter-show input{
  border-top: 1px dotted #dbdbdb !important;
  border-bottom: 1px dotted #dbdbdb !important;
}
.counter-btn {
  border: 1px dotted #dbdbdb !important;
}

.gl-i-wrap .p-operate{overflow: visible;}
.gl-i-wrap .p-operate {padding-right: 30px;}
.el-badge__content.is-fixed{z-index: 9999;}

.el-pagination{font-weight: normal;}
.el-pager .number:hover{color: #f42424 !important;}
.el-pager .active{background-color: #f42424 !important;color: #fff;}
.el-pager .active:hover{color: #fff !important;}

.brief{clear: both;color: blue;font-weight: bold;}

.original-price{text-decoration:none}
.gl-warp-large .original-price{padding-left: 48px;font-size: 16px;}

.p-name{
  font-size: 14px;
  font-weight: bold;
}

.tip{
  background-color: #e6a23c;
  padding: 12px 5px 12px;
  color: #fff;
  margin-bottom: 8px;
}
</style>
